import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Analytics } from "@vercel/analytics/react"
import ComingSoon from './components/ComingSoon';
import Header from "./components/Header";
import Hero from "./components/Hero";
import Programs from './components/Programs';
import ProgramDetails from './components/ProgramDetails';
import GuidanceCallSection from './components/GuidanceCallSection';
import WhyChooseUs from './components/WhyChooseUs';
import Stats from './components/Stats';
import Testimonials from './components/Testimonials';
import FAQs from './components/FAQs';
import JoinUsToday from './components/JoinUsToday';
import Footer from './components/Footer';
import StudentsPage from './components/StudentsPage';
import CountriesPage from './components/CountriesPage';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<>
            <ComingSoon />
          </>} />
          <Route path="/home" element={<>
            <Hero />
            <Programs />
            <WhyChooseUs />
            <GuidanceCallSection />
            <Stats />
            <Testimonials />
            <FAQs />
            <JoinUsToday />
          </>} />
          <Route path="/programs" element={<Programs />} />
          <Route path="/programs/:programTitle" element={<ProgramDetails />} />
          <Route path="/students" component={StudentsPage} />
          <Route path="/countries" component={CountriesPage} />
        </Routes>
        
        <Footer />
        <Analytics />
      </div>
    </Router>
  );
}

export default App;