import React from 'react';
import { Calendar, HelpCircle, UserCheck } from 'lucide-react';


const GuidanceCallSection = () => {
  const handleBookCall = () => {
    // You would replace this with your actual booking link or modal
    window.open('https://calendly.com/your-booking-page', '_blank');
  };

  return (
    <section className="bg-gray-50 py-16 px-4">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between">
        <div className="md:w-2/3 mb-6 md:mb-0 md:pr-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">
            Unsure Which Course is Right for You?
          </h2>
          <p className="text-lg text-gray-600 mb-6">
            Schedule a free 20-minute consultation with our career guidance experts. 
            We'll help you find the perfect learning path tailored to your career goals, 
            experience level, and aspirations.
          </p>
          <div className="flex items-center space-x-4">
            <ul className="space-y-3 text-gray-700">
              <li className="flex items-center">
                <UserCheck className="mr-2 text-purple-600" size={24} />
                Personalized career advice
              </li>
              <li className="flex items-center">
                <HelpCircle className="mr-2 text-purple-600" size={24} />
                Clear program recommendations
              </li>
              <li className="flex items-center">
                <Calendar className="mr-2 text-purple-600" size={24} />
                No-obligation 20-minute call
              </li>
            </ul>
          </div>
        </div>
        <div className="md:w-1/3 text-center">
          <button 
            onClick={handleBookCall}
            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300 transform hover:scale-105 shadow-lg"
          >
            Book Your Free Consultation
          </button>
        </div>
      </div>
    </section>
  );
};

export default GuidanceCallSection;